import {extractIndoorMapLink} from "../util/util";
import GoogleMapLoader from "./map/GoogleMapLoader";
import CallHistory from "./CallHistory";
import React from "react";

const Maps = ({locations, reportedGps, topEmlLocation = "", aps, callUuid, history}) => {
    let hrl = topEmlLocation.humanReadableLocation || "";
    hrl = hrl.replace(/(\n)+/g, "\n");
    let {strippedHrl} = extractIndoorMapLink(hrl);
    const isEliOfficeLocation = strippedHrl.toLocaleLowerCase().indexOf("bedford") !== -1
        && strippedHrl.toLocaleLowerCase().indexOf("380") !== -1;
    const isBAHLocation = strippedHrl.toLocaleLowerCase().indexOf("1610 county road") !== -1
        && strippedHrl.toLocaleLowerCase().indexOf("burnet") !== -1;
    return <div className="flex flex-col">
        <div className="w-screen h-screen max-w-full max-h-full z-5 flex flex-col">
            <GoogleMapLoader
                isEliOfficeLocation={isEliOfficeLocation}
                isBAHLocation={isBAHLocation}
                locations={locations}
                reportedGps={reportedGps}
                aps={aps}
                width="400px"
                height="400px"
            />
        </div>
        <CallHistory callUuid={callUuid} history={history}/>
    </div>
};

export default Maps;