import {memo, useCallback, useEffect, useMemo, useState} from "react";
import {MapContainer, Marker, ScaleControl, Tooltip, ZoomControl} from "react-leaflet";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {Icon} from "leaflet";
import FloorControl from "./controls/FloorControl";
import {ToggleControl} from "./controls/ToggleControl";

const GoogleMapLoader = ({isEliOfficeLocation, isBAHLocation, reportedGps = [], locations = [], aps = []}) => {
    const [mapLoading, setMapLoading] = useState(true);
    const [displayAps, setDisplayAps] = useState(false);
    const [displayLocations, setDisplayLocations] = useState(true);
    const [selectedFloor, setSelectedFloor] = useState("");

    const GoogleMarkerIcon = (isLast) => {
        return new Icon({
            iconUrl: '/static/img/google-loc.png',
            iconSize: [35, 35],
            className: isLast ? "" : "grayscale-80"
        })
    }
    const EmlMarkerIcon = (isLast) => new Icon({
        iconUrl: '/static/img/eml-loc.png',
        iconSize: [35, 35],
        className: isLast ? "" : "grayscale-80"
    })
    const RouterIcon = new Icon({
        iconUrl: '/static/img/router-icon.svg',
        iconSize: [35, 35]
    })
    const floors = useMemo(() => isEliOfficeLocation ? [
        {name: "F1", url: "/static/img/1_c.tif", value: "1"},
        {name: "F2", url: "/static/img/2_c.tif", value: "2"},
        {name: "F3", url: "/static/img/3_c.tif", value: "3"}
    ] : isBAHLocation ? [{
        name: "F1",
        url: "/static/img/ranch.tif",
        value: "1"
    }] : [], [isEliOfficeLocation, isBAHLocation]);

    useEffect(() => {
        if (isEliOfficeLocation) {
            setMapLoading(true);
            return;
        }
        setMapLoading(false);
    }, [isEliOfficeLocation, setMapLoading]);

    const setLoaded = useCallback((loaded) => setMapLoading(!loaded), [setMapLoading]);

    const deviceReportedGpsLocations = reportedGps
        .filter(loc => loc.lat && loc.lng)
        .filter(loc => !selectedFloor || loc.floor === selectedFloor)
        .reverse();

    const alsLocations = locations
        .filter(loc => loc.lat && loc.lng)
        .filter(loc => !selectedFloor || loc.floor === selectedFloor)
        .reverse();
    return <>
        <div className="w-full h-[30px] bg-slate-200">
            {mapLoading && <div className='h-1.5 w-full bg-blue-100 overflow-hidden'>
                <div className='animate-progress w-full h-full bg-blue-300 origin-left-right'></div>
            </div>
            }
        </div>
        <MapContainer attributionControl={false}
                      center={EARTH_CENTER_LAT_LNG}
                      zoom={3}
                      scrollWheelZoom={true}
                      zoomControl={false}
        >
            <ReactLeafletGoogleLayer apiKey='AIzaSyADlCDm6O5btPvU5PzWPbBBJfsSataS6ds' type={'satellite'}/>
            <ScaleControl position="bottomright"/>
            <ZoomControl position="topright"/>
            <FloorControl floors={floors} setLoaded={setLoaded} handleSelectFloor={setSelectedFloor}/>
            <ToggleControl className="ml-32"
                           position="topleft"
                           label="User Locations"
                           disabled={!locations.length && !reportedGps.length}
                           toggleState={displayLocations}
                           setToggleState={setDisplayLocations}/>
            <ToggleControl className="ml-80"
                           position="topleft"
                           label="APs"
                           disabled={!aps?.length}
                           toggleState={displayAps}
                           setToggleState={setDisplayAps}/>
            {
                displayLocations
                    ? deviceReportedGpsLocations
                        .map(((loc, idx) => {
                            return <Marker key={`g-${idx}-${loc.lat}-${loc.lng}`} position={[loc.lat, loc.lng]}
                                           icon={GoogleMarkerIcon(idx === deviceReportedGpsLocations.length - 1)}>
                                <Tooltip>{idx + 1}</Tooltip>
                            </Marker>
                        }))
                    : null
            }
            {
                displayLocations
                    ? alsLocations
                        .map(((loc, idx) => {
                            return <Marker key={`e-${idx}-${loc.lat}-${loc.lng}`}
                                           position={[loc.lat, loc.lng]}
                                           icon={EmlMarkerIcon(idx === alsLocations.length - 1)}>
                                <Tooltip>{idx + 1}</Tooltip>
                            </Marker>
                        }))
                    : null
            }
            {
                displayAps
                    ? aps.filter(ap => !selectedFloor || ap.floor === selectedFloor)
                        .map((ap, idx) => <Marker key={`ap-${idx}-${ap.lat}-${ap.lng}`}
                                                  position={[ap.lat, ap.lng]}
                                                  icon={RouterIcon}
                                                  opacity={100}>
                            <Tooltip>{ap.ssid} ({ap.bssid})</Tooltip>
                        </Marker>)
                    : null
            }
        </MapContainer>
    </>
};

export default memo(GoogleMapLoader);
const EARTH_CENTER_LAT_LNG = {lat: 54.331444157894936, lng: -108.15223571159713};